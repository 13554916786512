import styled from '@emotion/styled'

export const SingleSliderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .btn-min, .btn-max {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 59px;
    .max-value {
      width: 60px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span {
      text-shadow: 0px 4px 7px rgba(0, 71, 147, 0.5);
      font-family: Roboto;
      font-size: 21px;
      font-weight: 500 !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
  }
  .slider-wrapper {
    width: 311px;
  }
  .rc-slider {
    position: relative;
    height: 14px;
    padding: 5px;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    top: -4px;
    width: 272px;
    margin: 0 auto;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    .rc-slider-handle {
      margin-left: initial !important;
    }
    .rc-slider-track {
      border-radius: 6px !important;
    }
  }

  .max-btn-container {
    position: relative;
    top: 0px;
    .max-btn-value {
      position: relative;
      top: 33px;
      text-align: center;
      font-size: 21px;
    }
  }
`;

export const MinMaxLabel = styled.div`
  opacity: 0.5;
  text-shadow: 0px 4px 7px rgba(0, 71, 147, 0.5);
  font-family: Roboto;
  font-size: 21px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
`;